export const useOgImage = (url: MaybeRef<string>) => {
  const img = useImage()
  const ogImage = computed(() => {
    return img(unref(url), {
      width: 600,
      type: 'webp',
      quality: 80,
      fit: 'contain',
    })
  })

  return {
    src: ogImage,
  }
}
